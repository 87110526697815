import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useToast } from 'packages/common'
import { fullName } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { useAsyncFnWithReset } from 'packages/utils/hooks'
import { Events, track } from 'packages/wiretap'

import { Slugs } from 'app/fieldapp/i18n'
import { useAppDispatch, useAppSelector } from 'app/fieldapp/store/hooks'
import { fetchHousekeeperManagersByZone } from 'app/fieldapp/store/housekeepers/actions'
import { getHousekeeperManagersForZone } from 'app/fieldapp/store/housekeepers/selectors'
import { getInspectionFlags } from 'app/fieldapp/store/inspectionFlags/selectors/getInspectionFlags'
import { ApplicationState } from 'app/fieldapp/store/store'
import { Disposition, TicketPostData } from 'app/fieldapp/store/tickets'
import { createTicket } from 'app/fieldapp/store/tickets/actions/createTicket'
import { CreateTicketDrawerUnit } from 'app/fieldapp/store/units'
import { fetchUnitAndInspectionFlagsById } from 'app/fieldapp/store/units/actions/fetchUnitAndInspectionFlagsById'
import { getUsersByHkIds } from 'app/fieldapp/store/users/selectors'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import {
  createTicketReducer,
  CreateTicketValuesState,
  initialCreateTicketState,
} from '../createTicketState/createTicket.reducer'
import { useMaintenanceDispositions } from '../useMaintenanceDispositions'
import { CreateTicketForm } from './CreateTicketForm'
import { InspectionFlagOption } from './InspectionFlagSelect'

export const DEFAULT_DISPOSITION_DATA: Partial<Disposition> = {
  id: '656a1006-38eb-4c3e-ba65-ee13a78d8a3e',
  tier3Display: 'Others',
}

const useTranslations = ({ unit }: { unit?: CreateTicketDrawerUnit }) => {
  const { t, ut } = useI18n()

  return {
    additionLabelsToggle: ut(Slugs.addAdditionalDetails),
    alreadyAddedToTickets: t(Slugs.alreadyAddedToTickets),
    assignmentPrefDefault: t(Slugs.assignmentPrefDefault),
    assignmentPreference: ut(Slugs.assignmentPreference),
    assignToMyselfLabel: t(Slugs.assignToMyself),
    cancel: t(Slugs.cancel),
    defaultDispositionDisplayValue: t(
      Slugs.createATicketDefaultDispositionDisplayValue,
    ),
    descriptionLabel: t(Slugs.description),
    dispositionIdLabel: `${t(Slugs.whatNeedsAttention)}`,
    dispositionsFailToLoadMessage: ut(
      Slugs.createATicketDispositionErrorMessage,
    ),
    failureMessage: ut(Slugs.createTicketMessageFailure),
    flaggedItems: t(Slugs.flaggedItems),
    headerText: unit
      ? `${ut(Slugs.createMaintenanceTicketHeaderWithUnit)} ${unit.unitCode}`
      : ut(Slugs.createMaintenanceTicketHeaderWithoutUnit),
    housekeepingManager: t(Slugs.housekeepingManager),
    notAddedToTickets: t(Slugs.notAddedToTickets),
    optional: t(Slugs.optional),
    required: `(${t(Slugs.required)})`,
    severityLabel: `${t(Slugs.severity)}`,
    severityLevels: {
      Medium: ut(Slugs.ticketSeverityMedium),
      'Prior to Next Guest': ut(Slugs.ticketSeverityPriorNextGuest),
      Urgent: ut(Slugs.severityUrgent),
    },
    submit: t(Slugs.submit),
    titleLabel: t(Slugs.title),
    unit: ut(Slugs.unit),
    visibility: ut(Slugs.visibility),
    visibilityLabels: {
      internal: ut(Slugs.staffOnlyDefault),
      owner: ut(Slugs.staffAndOwner),
    },
  }
}

export type CreateTicketFormTranslations = ReturnType<typeof useTranslations>

type CreateTicketFormContainerProps = {
  closeForm: () => void
  onSetSelectedUnit: (unit: CreateTicketDrawerUnit) => void
  selectedUnit?: CreateTicketDrawerUnit
  taskId?: string
  /** If this is defined, the form will be for this unit with no way to change it, if it is not, we will show a unit selector */
  unit?: CreateTicketDrawerUnit
}

export const CreateTicketFormContainer: React.FC<
  CreateTicketFormContainerProps
> = ({ closeForm, unit, selectedUnit, onSetSelectedUnit, taskId }) => {
  const { showToast } = useToast()
  const { t, ut } = useI18n()
  const { delegateUserId, isEmployee, user } = useActiveUser()
  const appDispatch = useAppDispatch()

  const strings = useTranslations({ unit })
  const [state, dispatch] = React.useReducer(
    createTicketReducer,
    initialCreateTicketState,
  )

  const dispositions = useMaintenanceDispositions()

  const appliedUserId = delegateUserId || user?.id

  const zoneId = selectedUnit?.zone?.id || unit?.zone?.id || ''

  const housekeeperManagers = useSelector((state: ApplicationState) => {
    const hks = getHousekeeperManagersForZone(state, zoneId)
    return hks
  })

  const housekeeperManagersIds = housekeeperManagers.map(hk => hk.id)

  const housekeeperManagerUsers = useSelector((state: ApplicationState) => {
    return getUsersByHkIds(state, housekeeperManagersIds)
  })

  const inspectionFlags = useSelector((state: ApplicationState) => {
    return getInspectionFlags(state)
  })

  React.useEffect(() => {
    if (dispositions === 'error') {
      dispatch({
        // if we're using this default, we only need data with an id
        payload: { disposition: DEFAULT_DISPOSITION_DATA as Disposition },
        type: 'setValue',
      })
    }
  }, [dispositions])

  const onInputChange = React.useCallback(
    (section: keyof CreateTicketValuesState) => (value: string) =>
      dispatch({ payload: { [section]: value }, type: 'setValue' }),
    [],
  )

  const reduxDispatch = useDispatch()

  const [fetchHousekeepingManagersState, fetchHousekeepingManagersFn] =
    useAsyncFnWithReset(
      async (zoneId: string) => {
        return appDispatch(fetchHousekeeperManagersByZone(zoneId))
      },
      [appDispatch],
    )

  const [fetchInspectionFlagsState, fetchInspectionFlagsFn] =
    useAsyncFnWithReset(
      async (unitId: string) => {
        return appDispatch(fetchUnitAndInspectionFlagsById(unitId))
      },
      [appDispatch],
    )

  const onUnitChange = React.useCallback(
    (newUnit: CreateTicketDrawerUnit) => {
      onSetSelectedUnit(newUnit)
      fetchHousekeepingManagersFn(newUnit.zone?.id || '')
      fetchInspectionFlagsFn(newUnit.id)
    },
    [fetchHousekeepingManagersFn, fetchInspectionFlagsFn, onSetSelectedUnit],
  )

  const toggleDetails = React.useCallback(() => {
    dispatch({ type: 'toggleDetailsOpen' })
    fetchHousekeepingManagersFn(unit?.zone?.id || '')
    fetchInspectionFlagsFn(unit?.id || '')
  }, [
    fetchHousekeepingManagersFn,
    fetchInspectionFlagsFn,
    unit?.id,
    unit?.zone?.id,
  ])

  const setError = React.useCallback(
    (value: boolean) => dispatch({ payload: value, type: 'setError' }),
    [],
  )

  const setIsLoading = React.useCallback(
    (value: boolean) => dispatch({ payload: value, type: 'setLoading' }),
    [],
  )

  const toggleAssignToSelf = React.useCallback(
    () =>
      dispatch({
        payload: {
          assignToSelf: !state.values.assignToSelf,
        },
        type: 'setValue',
      }),
    [state.values],
  )

  const toggleInspectionFlag = React.useCallback(
    (
      options: InspectionFlagOption[],
      flag: { option: InspectionFlagOption },
    ) => {
      dispatch({
        payload: {
          changedFlagId: flag.option.flagId,
          flags: options.map(opt => opt.flagId),
        },
        type: 'toggleInspectionFlag',
      })
    },
    [],
  )

  // Construct array to be used as option values for the assignment preferences dropdown
  // Default will be to not send any assignee id
  const assigneeIdOptions = [
    'default',
    user?.id,
    ...housekeeperManagerUsers
      .filter(managerUser => managerUser.id !== user?.id)
      .map(managerUser => managerUser.id),
  ] as string[]

  // Construct a object with key/value pairs for translating the option labels.
  // The key is the option value from the assigneeIdOptions that we made above
  // and the value is the translated string to display
  const assigneeOptionTranslations = housekeeperManagerUsers
    .filter(managerUser => managerUser.id !== user?.id)
    .reduce(
      (acc, managerUser) => ({
        ...acc,
        [managerUser.id]: `${fullName(managerUser)} (${t(
          Slugs.housekeepingManager,
        )})`,
      }),
      {},
    )

  const userId = user?.id || ''

  // Add the translations for the default option and the option for the current user
  const allAssigneeOptionTranslations = {
    default: ut(Slugs.assignmentPrefDefault),
    [userId]: `${user?.firstName} ${user?.lastName}`,
    ...assigneeOptionTranslations,
  }

  const possibleVisit = useAppSelector((state: ApplicationState) =>
    getVisitById(state, taskId),
  )

  const taskIsVisit = !!taskId && possibleVisit !== undefined

  const displayVisibilityToggle = isEmployee

  const submit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setIsLoading(true)
      setError(false)
      try {
        const {
          assigneeId,
          assignToSelf,
          description,
          disposition,
          inspectionFlagIds,
          severity,
          title,
          visibility,
        } = state.values

        if (!(disposition && severity && appliedUserId && selectedUnit)) {
          /* This should never happen due to validation in the reducer but this makes
           * TS happy and adds in an extra layer of protection*/
          throw new Error('Trying to submit invalid data')
        }

        const data: TicketPostData = {
          assigneeId,
          assignToSelf,
          description,
          dispositionId: disposition?.id,
          inspectionFlagIds: inspectionFlagIds,
          severity,
          title,
          unitId: selectedUnit.id,
          userId: appliedUserId,
          visibility: visibility,
        }

        await reduxDispatch(createTicket(data))

        // If the ticket was created from a clean or visit drawer then we want to track the ticket id
        if (taskId) {
          /* eslint-disable @typescript-eslint/naming-convention */
          track(Events.fieldAppCreateTicketFromTask, {
            action: 'ticket_created',
            task_id: taskId,
            unit_id: selectedUnit?.id,
          })
          /* eslint-disable @typescript-eslint/naming-convention */
        }

        showToast({ message: ut(Slugs.createTicketSuccessMessage) })
        closeForm()
      } catch {
        setError(true)
      } finally {
        setIsLoading(false)
      }
    },
    [
      setIsLoading,
      setError,
      state.values,
      appliedUserId,
      selectedUnit,
      reduxDispatch,
      taskId,
      showToast,
      ut,
      closeForm,
    ],
  )

  const formState = React.useMemo(() => {
    const {
      assigneeId,
      assignToSelf,
      title,
      description,
      inspectionFlagIds,
      severity,
      disposition,
      visibility,
    } = state.values

    const { descriptionOpen, error, loading } = state.uiState

    return {
      allowAssignToSelf: !delegateUserId,
      assigneeId,
      assignToSelf,
      canSubmit: state.uiState.readyToSubmit,
      descriptionCharCount: description.length,
      disposition,
      dispositions,
      error,
      inspectionFlagIds,
      isDetailsOpen: descriptionOpen,
      isLoading: loading,
      selectedUnit,
      severity,
      titleCharCount: title.length,
      visibility: visibility,
    }
  }, [dispositions, delegateUserId, selectedUnit, state])

  const formEventHandlers = React.useMemo(
    () => ({
      cancel: closeForm,
      clearError: () => setError(false),
      onInputChange,
      onUnitChange: unit ? undefined : onUnitChange,
      submit,
      toggleAssignToSelf,
      toggleDetails,
      toggleInspectionFlag,
    }),
    [
      closeForm,
      onInputChange,
      onUnitChange,
      setError,
      submit,
      toggleAssignToSelf,
      toggleDetails,
      toggleInspectionFlag,
      unit,
    ],
  )

  return (
    <CreateTicketForm
      assigneeOptions={assigneeIdOptions}
      assigneeOptionTranslations={allAssigneeOptionTranslations}
      displayVisibilityToggle={displayVisibilityToggle}
      eventHandlers={formEventHandlers}
      inspectionFlags={inspectionFlags}
      loadingHousekeepingManagers={fetchHousekeepingManagersState.loading}
      loadingInspectionFlags={fetchInspectionFlagsState.loading}
      state={formState}
      strings={strings}
      taskIsVisit={taskIsVisit}
    />
  )
}
