import React from 'react'
import { useLocation } from 'react-router-dom'

import {
  AppAuthState,
  getRefreshTokenRequest,
  getTokenRequest,
  useAppAuth as usePackagedAppAuth,
} from 'packages/auth'
import { redirectForAuthentication } from 'packages/auth'
import { CACHE_KEY_CODE_VERIFIER } from 'packages/auth/src/utils/generateCodeChallenge'
import { splitSearchQuery } from 'packages/utils/misc'

import { SHARED_CLEAN_ROOT_URL } from 'app/fieldapp/components/schedule/schedule.utils'
import { initI18n } from 'app/fieldapp/i18n'
import {
  setNeedsFullAuthRedirect,
  setTokens,
} from 'app/fieldapp/store/auth/auth.slice'
import {
  getAuthToken,
  getImpersonationToken,
  getNeedsFullAuthRedirect,
  getNeedsSilentRefresh,
  getRefreshToken,
} from 'app/fieldapp/store/auth/selectors'
import { useAppDispatch, useAppSelector } from 'app/fieldapp/store/hooks'

const {
  REACT_APP_AUTH_BASE_URL: AUTH_URL = '',
  REACT_APP_AUTH_TOKEN_URL: TOKEN_URL = '',
  REACT_APP_AUTH_CLIENT_ID_FIELDAPP: AUTH_CLIENT_ID = '',
} = process.env

export const useAppAuth = (): { authState: AppAuthState } => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { authState, onAppAuthInitialized } = usePackagedAppAuth()

  const authToken = useAppSelector(getAuthToken)
  const impersonationToken = useAppSelector(getImpersonationToken)
  const refreshToken = useAppSelector(getRefreshToken)
  const needsFullAuth = useAppSelector(getNeedsFullAuthRedirect)
  const needsSilentRefresh = useAppSelector(getNeedsSilentRefresh)

  const [code, setCode] = React.useState('')

  const isSharedClean = React.useMemo(
    () => location.pathname.includes(SHARED_CLEAN_ROOT_URL),
    [location.pathname],
  )

  const handleAuthInitialized = React.useCallback(
    async (initialUrl?: string) => {
      await initI18n()
      await onAppAuthInitialized(initialUrl)
    },
    [onAppAuthInitialized],
  )

  /*
   * If the current user is a delegate token user viewing a shared clean, we must skip the entire
   * authentication bootstrapping process, and just directly use the delegate token provided in the URL params instead.
   * In this case, we will not render any of the authentication handling components.
   */
  React.useEffect(() => {
    if (!isSharedClean) return
    // since we split the token into query params, we need to re-construct it before sending to store.
    const {
      b: body = '',
      h: header = '',
      s: sig = '',
    } = splitSearchQuery(window.location.search)

    // ensure we have all necessary components to make the token; otherwise we might end up with
    // a token like ".." after joining, which we obviously do not want
    const canCombineToken = !!body && !!header && !!sig
    const delegateToken = canCombineToken ? [header, body, sig].join('.') : ''

    dispatch(
      setTokens({
        accessToken: undefined,
        delegateToken,
        idToken: undefined,
        refreshToken: undefined,
      }),
    )

    handleAuthInitialized(SHARED_CLEAN_ROOT_URL)
  }, [dispatch, handleAuthInitialized, isSharedClean])

  /**
   * Handles the initial authentication request.
   * - If we already have a "code" from the server, it will be saved locally so we can exchange it for a token.
   * - Otherwise, a full auth redirect is triggered
   */
  React.useEffect(() => {
    if (isSharedClean) return

    if (!navigator.onLine) {
      dispatch(
        setTokens({
          accessToken: undefined,
          idToken: undefined,
          refreshToken: undefined,
        }),
      )

      handleAuthInitialized(authState.initialUrl)
      return
    }

    if (needsFullAuth) {
      const params = splitSearchQuery(location.search)
      const { code } = params

      if (code) {
        setCode(code)
      } else {
        redirectForAuthentication(AUTH_URL, AUTH_CLIENT_ID)
      }
    }
  }, [
    authState.initialUrl,
    dispatch,
    handleAuthInitialized,
    isSharedClean,
    location.search,
    needsFullAuth,
  ])

  /**
   * Handles the "code-to-token exchange" flow.
   * - When we have a "code" from the auth server, will attempt to exchange that code for an auth token
   * - If any errors occur, we will simply trigger a full auth redirect
   */
  React.useEffect(() => {
    if (isSharedClean) return

    const getToken = async () => {
      try {
        const request = getTokenRequest(code, AUTH_CLIENT_ID)
        const res = await fetch(TOKEN_URL, request)
        const data = await res.json()
        const {
          access_token: accessToken,
          id_token: idToken,
          refresh_token: refreshToken,
        } = data

        dispatch(setTokens({ accessToken, idToken, refreshToken }))
        handleAuthInitialized(authState.initialUrl)
      } catch (err) {
        // manually setting our token to an impersonated user's token will attempt to trigger this,
        // so we need to manually stop it when this is present
        if (!!impersonationToken) {
          dispatch(setNeedsFullAuthRedirect(true))
        }
      } finally {
        sessionStorage.removeItem(CACHE_KEY_CODE_VERIFIER)
      }
    }

    if (code && !authToken) {
      getToken()
    }
  }, [
    authState.initialUrl,
    authToken,
    code,
    dispatch,
    impersonationToken,
    isSharedClean,
    handleAuthInitialized,
  ])

  /**
   * Handles the "refresh token" flow.
   * - When 'needsSilentRefresh' is true, will attempt to request a new token using our existing refresh token
   * - If any errors occur, we will simply trigger a full auth redirect
   */
  React.useEffect(() => {
    if (isSharedClean) return

    const refreshAuthToken = async () => {
      try {
        if (!refreshToken) {
          throw Error(
            'No refresh token found; full authentication redirect required.',
          )
        }

        const request = getRefreshTokenRequest(refreshToken, AUTH_CLIENT_ID)
        const res = await fetch(TOKEN_URL, request)
        const data = await res.json()
        const {
          access_token: accessToken,
          id_token: idToken,
          refresh_token: newRefreshToken,
        } = data

        if (idToken && newRefreshToken) {
          dispatch(
            setTokens({
              accessToken,
              idToken,
              refreshToken: newRefreshToken,
            }),
          )
        } else {
          throw Error(
            'No valid tokens found; full authentication redirect required.',
          )
        }
      } catch (err) {
        dispatch(setNeedsFullAuthRedirect(true))
      }
    }

    if (needsSilentRefresh) {
      refreshAuthToken()
    }
  }, [code, dispatch, isSharedClean, needsSilentRefresh, refreshToken])

  return { authState }
}
