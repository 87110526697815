import React from 'react'

import {
  Loader,
  SentryErrorBoundary,
  ServiceWorkerWrapper,
} from 'packages/common'

import { register as registerSW } from '../../serviceWorker'
import { RefreshOnFocus, UpdateNotifier } from './components'
import FieldAppRoot from './components/FieldAppRoot'
import { FIELD_APP_CONTAINER_ID } from './FieldApp.constants'
import { useAppAuth } from './utils/hooks/useAppAuth'

import styles from './FieldApp.module.scss'

const FieldApp: React.FC = () => {
  const { authState } = useAppAuth()

  return (
    <SentryErrorBoundary boundary={'fieldapp-root'}>
      <RefreshOnFocus />

      {!authState.loaded ? (
        <Loader />
      ) : (
        <div className={styles.fieldAppViewWrapper} id={FIELD_APP_CONTAINER_ID}>
          <ServiceWorkerWrapper
            registerSW={registerSW}
            updateNotifier={<UpdateNotifier />}
          />

          <FieldAppRoot initialUrl={authState.initialUrl} />
        </div>
      )}
    </SentryErrorBoundary>
  )
}

export default FieldApp
