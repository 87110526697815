import styled from '@emotion/styled'
import { useDecision } from '@optimizely/react-sdk'
import React from 'react'
import { useSelector } from 'react-redux'

import { IconName } from 'packages/iconic'
import { Features } from 'packages/optimizely'

import * as SharedStyles from 'app/fieldapp/components/schedule/components/TaskSharedStyles'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { getContactsByUnitId } from 'app/fieldapp/store/contacts/selectors/getContactsByUnitId'
import { ApplicationState } from 'app/fieldapp/store/store'

import { OwnerInfoLink } from './OwnerInfo.styles'

const St = {
  ...SharedStyles,
  Container: SharedStyles.UnitInfoContainer,
  Details: styled(SharedStyles.Details)`
    display: flex;
    flex-direction: column;
  `,
}

type OwnerInfoProps = {
  unitId?: string
}

export const OwnerInfo: React.FC<OwnerInfoProps> = ({ unitId }) => {
  const { t } = useI18n()
  const [decision] = useDecision(Features.SHOW_OWNER_INFO)
  const isOwnerInfoEnabled = decision.enabled

  const contacts = useSelector((state: ApplicationState) =>
    getContactsByUnitId(state, unitId || ''),
  )

  return (
    <>
      {contacts !== undefined &&
        contacts.length > 0 &&
        contacts.length < 2 &&
        isOwnerInfoEnabled && (
          <St.Container>
            <St.DetailIcon icon={IconName.user2} size={16} />
            <St.Details>
              <St.SectionSubTitle>{t(Slugs.ownerInfo)}</St.SectionSubTitle>
              <St.BasicText>
                {(contacts[0].firstName || contacts[0].lastName) && (
                  <>
                    <span>
                      {contacts[0].firstName} {contacts[0].lastName}
                    </span>
                    <br />
                  </>
                )}
                {contacts[0].email && (
                  <>
                    {contacts[0].email.split(',').map((email, index) => (
                      <OwnerInfoLink href={`mailto:${email}`} key={index}>
                        <span>{email.trim()}</span>
                        <br />
                      </OwnerInfoLink>
                    ))}
                  </>
                )}
                {contacts[0].phone && (
                  <OwnerInfoLink href={`tel:${contacts[0].phone}`}>
                    {contacts[0].phone}
                  </OwnerInfoLink>
                )}
              </St.BasicText>
            </St.Details>
          </St.Container>
        )}
    </>
  )
}
